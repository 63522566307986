import {oaService} from './index'

function getWXList(id) {
  return oaService.get('/operate/wx-data?cityId=' + id)
}

function add(params) {
  return oaService.post('/operate', params)
}

function searchEp(name) {
  return oaService.get('/operate/search/list?name=' + name)
}

function getPosList(type, id) {
  let url
  if (type === 'ep') {
    url = '/operate/hr-pos/list?enterpriseId='
  }
  if (type === 'order') {
    url = '/operate/pos-list?operateId='
  }
  return oaService.get(url + id)
}

function getHRList(id) {
  return oaService.get('/operate/hr/list?enterpriseId=' + id)
}

function getUserList() {
  return oaService.get('/data/getUserList')
}
function getCityByUser(id) {
  return oaService.get('/operate/city-list?userId=' + id)
}

function getList(type, params) {
  const url = type === 1 ? '/operate/day/list' : '/operate/list'
  return oaService.post(url, params)
}

// 编辑获取详情
function getDetail(id) {
  return oaService.get('/operate/detail?id=' + id)
}
function update(params) {
  return oaService.put('/operate', params)
}

function getTable(date) {
  return oaService.post('/operate/download-list', {date})
}

function getUserDayData(params) {
  return oaService.post('/operate/day/list/new', params)
}


export default {
  getWXList,
  add,
  searchEp,
  getPosList,
  getHRList,
  getUserList,
  getCityByUser,
  getList,
  getDetail,
  update,
  getTable,
  getUserDayData,
}

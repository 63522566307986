import {useEffect, useState} from 'react'
import ajax from '../ajax/operateData'
import Dayjs from 'dayjs'
import { Tooltip } from 'antd'

export default function UserDayDataPanel({date, city}) {
  const [data, setData] = useState({})
  useEffect(() => {
    if (date && city) {
      const d = Dayjs(date)
      let startTime, endTime
      startTime = d.startOf('month')
      endTime = d.endOf('month')
      const params = {
        startTime: startTime.format('YYYY-MM-DD'),
        endTime: endTime.format('YYYY-MM-DD'),
        cityId: city
      }
      ajax.getUserDayData(params).then(res => {
        if (res.success) {
          setData(res.data)
        }
      })
    }
  }, [date, city])

  return (
    <div className="user-day-data">
      <div className="td">
        <div className="num">{data.distinctCount || 0}</div>
        <div className="label">品牌合计</div>
      </div>
      <Tooltip title={<Str list={data.posList} />} >
        <div className="td hand">
          <div className="num">
            <span>{data.dayPosCount || 0}/</span>
            <span className={color(data.comparePosCount)}>{data.comparePosCount > 0 ? '+' : ''}{data.comparePosCount || 0}</span>
          </div>
          <div className="label">平均职位数/较上月对比</div>
        </div>
      </Tooltip>
      <div className="td">
        <div className="num">
          <span>{data.dayReadCount || 0}/</span>
          <span className={color(data.compareReadCount)}>{data.compareReadCount > 0 ? '+' : ''}{data.compareReadCount || 0}</span>
        </div>
        <div className="label">平均阅读量/较上月对比</div>
      </div>
      <div className="td">
        <div className="num">{data.totalFansCount || 0}/{data.totalAddCount || 0}</div>
        <div className="label">公众号总数/总新增</div>
      </div>
      <div className="td">
        <div className="num">{data.totalWxFriendCount || 0}/{data.totalWxAddCount || 0}</div>
        <div className="label">微信好友总数/总新增</div>
      </div>
      <div className="td">
        <div className="num">{data.totalWxTransformCount || 0}</div>
        <div className="label f-600">付费总数</div>
      </div>
    </div>
  )
}

function Str({list}) {
  if (list) {
    return (
      <div>
        {list.map((it, i) => <div key={i}>{it.name}：{it.value}</div> )}
      </div>
    )
  }
  return '暂无数据'
}

function color(input) {
  if (input) {
    if (input > 0) {
      return 'green'
    }
    if (input < 0) {
      return 'red'
    }
  }
  return ''
}